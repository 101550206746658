import { Injectable } from '@angular/core';
import { ClientPortalEnvironment } from '@client-portal/models/environment';
import { environment } from './environments/environment';

@Injectable({ providedIn: 'root' })
export class ClpEnvironment implements ClientPortalEnvironment {
  public production = environment.production;
  public featureFlags = environment.featureFlags;
  public pwa = environment.pwa;
  public auth0 = environment.auth0;
  public apiEndpoint = environment.apiEndpoint;
  public gtmId = environment.gtmId;
  public bugsnagReleaseStage = environment.bugsnagReleaseStage;
  public chatLicenseId = environment.chatLicenseId;
}
