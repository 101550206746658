import { ClientPortalEnvironment } from '@client-portal/models/environment';

export const environment: ClientPortalEnvironment = {
  production: true,
  pwa: true,
  featureFlags: {
    hideOrderTypes: true,
    hideUpgradeOrders: true,
    hideChecklist: false,
    hideRecentOrder: true,
    hideOurFeatures: true,
  },
  auth0: {
    domain: 'auth.tnssng.com',
    clientId: 'XVU347QBBLyiBbwrBIFGqcNZgY2eyzP4',
    redirectUrl: `${window.location.origin}/callback`,
    logoutUrl: window.location.origin,
    errorPath: '/error',
  },
  apiEndpoint: 'https://app-clientportal-api-prod.azurewebsites.net',
  gtmId: 'GTM-PFC79MR6',
  bugsnagReleaseStage: 'Production',
  chatLicenseId: '12261867',
};
